@import '~sanitize.css'; //https://github.com/csstools/sanitize.css

/*$bkgColour: #002b47;*/

$bkgColour: #ffffff;
$frgColour: #fff;
$primaryTextColour: #1b1b1b;
$highlightTextColour: #313224;
$rollover: #ffffff;
$coreMenuZIndex: 10;
$enlargedZIndex: 20;
$coreMenuHeight: 100px;
$menuTextColour: #F7F5EE;

@mixin font-primary {
  font-family: 'SohneBreit', Verdana, Geneva, sans-serif;
  font-style: normal;
  font-weight: 400;
}

@mixin font-body {
  font-family: 'PPEiko-Thin', Verdana, Geneva, sans-serif;
}

@mixin font-thin {
  font-family: 'PPEiko-Thin-100', Verdana, Geneva, sans-serif;
}

@mixin font-secondary {
  font-family: 'Brown';
  font-style: normal;
  font-weight: 400;
}

@mixin font-header {
  font-family: 'SohneBreit';
  letter-spacing: -0.2px;
  font-weight: 400;
}

body {
  background: $frgColour;
  overflow-x: hidden;
}


button {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}


.shared--loading {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  &:before {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100px;
    margin: -50px 0px 0px -50px;
    content: "loading...";
    color: $primaryTextColour;
    text-align: center;
    @include font-primary();
    font-size: 20px;
  }

  &:after {
    position: absolute;
    top: 50%;
    left: 50%;
    content: " ";
    width: 12px;
    height: 12px;
    border-radius: 50%;
    display: block;
    margin: -6px 0px 0px -6px;
    color: #FFF;
    box-sizing: border-box;
    animation: animloader 1.5s linear infinite;
  }
}


@keyframes animloader {
  0% {
    box-shadow: 14px 0 0 -2px, 38px 0 0 -2px, -14px 0 0 -2px, -38px 0 0 -2px;
  }

  25% {
    box-shadow: 14px 0 0 -2px, 38px 0 0 -2px, -14px 0 0 -2px, -38px 0 0 2px;
  }

  50% {
    box-shadow: 14px 0 0 -2px, 38px 0 0 -2px, -14px 0 0 2px, -38px 0 0 -2px;
  }

  75% {
    box-shadow: 14px 0 0 2px, 38px 0 0 -2px, -14px 0 0 -2px, -38px 0 0 -2px;
  }

  100% {
    box-shadow: 14px 0 0 -2px, 38px 0 0 2px, -14px 0 0 -2px, -38px 0 0 -2px;
  }
}


.shared--load-container {
  position: absolute;
  display: none;
  width: 100%;
  height: 100%;
  background: $bkgColour;
  opacity: 0;
}


.shared--img-fill {
  width: 100%;
  height: auto;
}

.shared--img-fill-map {
  width: 92.95%;
  height: auto;
}



.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}