@import "../../../shared/shared.scss";

.menu {
    padding-top: 70px;
    list-style-type: none;
}

.side-menu-main {
    margin-bottom: 40px;
    height: 350px;
}

.menu-group {
    width: 10%;
    height: 390px;
    margin-top: 700px;
    position: absolute;
    right: 50px;
    border-radius: 20px;
    padding-top: 5px;
}

.menu-items {
    width: 10%;
    height: 50px;
    margin-top: 780px;
    position: absolute;
    right: 50px;

}

.menu-group-item {
    margin-top: 115px;
    list-style-type: none;
    font-size: 24px;
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-left: 0px;
    @include font-header;

    color: $rollover;
    cursor: pointer;

    &:hover {
        color: white;
    }
}

/*%shared-menu-item-styles {
    background-color: $highlightTextColour;
    width: 130px;
    height: 40px;
    border-radius: 35px;
    border: 1px solid white;
    display: flex;
    align-items: center;
    margin-top: 30px;
    list-style-type: none;
    font-size: px;
    text-transform: uppercase;
    text-align: center;
    display: flex;
    justify-content: center;
    margin-left: 0px;
    @include font-header;
    color: $rollover;
    cursor: pointer;

    &:hover {
        color: rgb(94, 21, 21);       
    }
}

.menu-item,
.menu-item-selected {
    @extend %shared-menu-item-styles

}*/
.menu-item {
    background-color: $highlightTextColour;
    width: 130px;
    height: 40px;
    border-radius: 20px;
    border: 1px solid #969890; 
    display: flex;
    align-items: center;
    margin-top: 30px;
    list-style-type: none;
    font-size: px;
    text-transform: uppercase;
    text-align: center;
    display: flex;
    justify-content: center;
    margin-left: 2px;
    @include font-header;
    color: #969890;  
    cursor: pointer;

    &:hover {
        color: #ffffff;
        border: 1px solid white;       
    }
}

.menu-item-selected {
    background-color: $highlightTextColour;
    width: 130px;
    height: 40px;
    border-radius: 20px;
    border: 1px solid white;
    display: flex;
    align-items: center;
    margin-top: 30px;
    list-style-type: none;
    font-size: px;
    text-transform: uppercase;
    text-align: center;
    display: flex;
    justify-content: center;
    margin-left: 2px;
    @include font-header;
    color: $rollover;
    cursor: pointer;

    &:hover {
        color: rgb(255, 255, 255);       
    }
}

.menu-dusk-sunset {
    background-color: $highlightTextColour;
    width: 135px;
    height: 40px;
    border-radius: 20px;
    border: 1px solid white;
    display: flex;
    align-items: center;
    margin-top: -250px;
    list-style-type: none;
    font-size: 18px;
    text-align: center;
    display: flex;
    justify-content: center;
    margin-left: -16px;
    @include font-header;
    color: $rollover;
}

.menu-dusk-sunset {
    background-color: $highlightTextColour;
    width: 135px;
    height: 40px;
    border-radius: 20px;
    border: 1px solid white;
    display: flex;
    align-items: center;
    margin-top: -250px;
    list-style-type: none;
    font-size: 18px;
    text-align: center;
    display: flex;
    justify-content: center;
    margin-left: -16px;
    @include font-header;
    color: $rollover;
}

.menu-item-dusk-sunset {
    padding-left: 4px;
    cursor: pointer;

    &:hover {
        color: white;
    }
}

.menu-dusk-sunset-single {
    background-color: $highlightTextColour;
    width: 134px;
    height: 40px;
    border-radius: 20px;
    border: 1px solid white;
    display: flex;
    align-items: center;
    margin-top: -250px;
    list-style-type: none;
    font-size: 18px;
    text-align: center;
    display: flex;
    justify-content: center;
    margin-left: -16px;
    @include font-header;
    color: $rollover;
}

.menu-item-dusk-sunset-selected {
    padding-left: 4px;
    cursor: pointer;
    color: white;
}